import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import type { Result } from "~/data/types";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function extractDotFromSlug(slug: string): Result<string> {
  const dot = slug.split("-").pop() ?? "";
  try {
    parseInt(dot.trim());
    return success(dot.trim());
  } catch (error) {
    return failure(new Error("Invalid DOT number"));
  }
}

export function isSuccess<T>(
  result: Result<T>,
): result is { data: T; kind: "success" } {
  return result.kind === "success";
}
export function isFailure<T>(
  result: Result<T>,
): result is { error: any; kind: "failure" } {
  return result.kind === "failure";
}

export function getError<T>(result: Result<T>): any {
  if (isFailure(result)) {
    return result.error;
  }
  return null;
}

export function unwrap<T>(result: Result<T>): T {
  if (isSuccess(result)) {
    return result.data;
  }
  throw result.error;
}

export function getSuccess<T>(result: Result<T>): T | null {
  if (isSuccess(result)) {
    return result.data;
  }
  return null;
}

export function failure(error: any) {
  return { error, kind: "failure" as const };
}

export function success<T>(data: T): Result<T> {
  return { data, kind: "success" };
}

export function capitalizeName(name: string): string {
  return name
    .split(" ")
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
    .join(" ");
}
